.clearance-summary {
    display: flex;
    flex-wrap: wrap;

    > .clearance {
        flex-grow: 1;
        margin: .5em;
        padding: .5em;

        @media (min-width: 900px) {
            flex-basis: 200px;
        }
    }
}

.clearance {
    text-align: center;

    &.clearance-complete {
      background: var(--color-first-green);
    }
    &.clearance-incomplete {
      background: var(--color-first-red);
      color: var(--color-first-gray);

      a,
      a:link {
        color: white;
      }
    }

    &.block {
        @media (min-width: 900px) {
            flex-basis: 200px;
        }
    }

    .clearance-name {
        font-weight: bold;
    }

}
